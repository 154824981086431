import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Layout, Spin} from '@douyinfe/semi-ui';
import VChart from '@visactor/vchart';
import {Multiplier, API, isAdmin, showError, timestamp2string1} from '../../helpers';
import {
    getQuotaWithUnit,
    modelColorMap,
    renderNumber, renderQuota, renderQuotaNumberWithDigit,
} from '../../helpers/render';

import styled from 'styled-components';
import {intl} from '../../lang';
import {UserContext} from "../../context/User";

const StatisticsWapper = styled.div`
    display: flex;
    gap: 16px;
    padding: 12px;

    .item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1;
        width: 0;
        padding: 12px;
        border-radius: 6px;

        .show {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: rgb(245, 245, 245);
        }

        .base {
            // text-decoration: line-through;
            color: #c6c6c6;
        }
    }

    .item:nth-child(1) {
        background-color: #4096ff;
    }

    .item:nth-child(2) {
        background-color: #4096ff;
    }

    .item:nth-child(3) {
        background-color: #4096ff;
    }
`

const Detail = (props) => {
    let now = new Date();
    const [inputs, setInputs] = useState({
        username: '',
        token_name: '',
        model_name: '',
        channel: '',
        data_export_default_time: '',
    });
    const isAdminUser = isAdmin();
    const [modelDataChart, setModelDataChart] = useState(null);
    const [lineChart, setLineChart] = useState(null);
    const [modelDataPieChart, setModelDataPieChart] = useState(null);
    const [loading, setLoading] = useState(false);
    const [avaiableBanlance, setAvaiableBanlance] = useState(0);
    const [total_consume, setTotal_consume] = useState(0);
    const [todayConsume, setTodayConsume] = useState(0);
    const [group, setGroup] = useState('');
    const [userState, userDispatch] = useContext(UserContext);
    const [lineData, setLineData] = useState([]);
    const [legendSelected, setLegendSelected] = useState({});
    const [legends, setLegends] = useState([]);
    let time = {}
    let timeLegend = {}
    const spec_line = {
        type: 'bar',
        data: [
            {
                id: 'barData',
                values: [],
            },
        ],
        xField: 'Time',
        yField: 'Usage',
        seriesField: 'Model',
        stack: true,
        legends: [
            {
                visible: true,
                select: false,
            }
        ],
        title: {
            visible: true,
            text: `${intl.get('pages.Detail.model-consumption-distribution')}`,
            subtext: '0',
        },
        bar: {
            state: {
                hover: {
                    stroke: '#000',
                    lineWidth: 1,
                }
            },
        },
        label: {
            visible: true,
            animation: false,
            position: 'top',
            overlap: false,
            offset: 15,
            // formatMethod: datum => `${datum}`,
            formatMethod: (datum, data, dataset) => {
                data[data.Time] = 0
                data.groupLastModel = ""
                for (let item of dataset.series._data._data.latestData) {
                    if (item.Time === data.Time) {
                        data[data.Time] += item.Usage
                        data.groupLastModel = item.Model
                    }
                }
                if (data.groupLastModel === data.Model) {
                    if (!time[data.Time]) {
                        time[data.Time] = Number(data[data.Time]).toFixed(2)
                        return '$' + time[data.Time]
                    }
                    return ''
                }
                return ''
            },
            style: {
                fill: 'rgb(155,155,155)'
            }
        },
        tooltip: {
            mark: {
                content: [
                    {
                        key: (datum) => datum['Model'],
                        value: (datum) => renderQuotaNumberWithDigit(parseFloat(datum['Usage']), 4),
                    },
                ],
            },
            dimension: {
                content: [
                    {
                        key: (datum) => datum['Model'],
                        value: (datum) => datum['Usage'],
                    },
                ],
            },
        },
        color: {
            specified: modelColorMap,
        },
    };
    const spec_pie = {
        type: 'pie',
        data: [
            {
                id: 'id0',
                values: [{ type: 'null', value: '0' }],
            },
        ],
        outerRadius: 0.8,
        innerRadius: 0.5,
        padAngle: 0.6,
        valueField: 'value',
        categoryField: 'type',
        pie: {
            style: {
                cornerRadius: 10,
            },
            state: {
                hover: {
                    outerRadius: 0.85,
                    stroke: '#000',
                    lineWidth: 1,
                },
                selected: {
                    outerRadius: 0.85,
                    stroke: '#000',
                    lineWidth: 1,
                },
            },
        },
        title: {
            visible: true,
            text: `${intl.get('pages.Detail.the-proportion-of-model-calls')}`,
        },
        legends: {
            visible: true,
            orient: 'left',
        },
        label: {
            visible: true,
        },
        tooltip: {
            mark: {
                content: [
                    {
                        key: (datum) => datum['type'],
                        value: (datum) => renderNumber(datum['value']),
                    },
                ],
            },
        },
        color: {
            specified: modelColorMap,
        },
    };

    const initPieChart = async () => {
        let pieChart = modelDataPieChart;
        if (!modelDataPieChart) {
            pieChart = new VChart(spec_pie, {dom: 'model_pie'});
            setModelDataPieChart(pieChart);
            pieChart.renderAsync();
        }
        setLoading(true);

        let url = '';
        if (isAdminUser) {
            url = `/api/log/model_usage`;
        } else {
            url = `/api/log/self/model_usage`;
        }
        const res = await API.get(url);
        const {success, message, data} = res.data;
        if (success) {
            if (data.length === 0) {
                data.push({
                    count: 0,
                    model_name: `${intl.get('pages.Detail.no-data')}`,
                    quota: 0,
                    created_at: now.getTime() / 1000,
                });
            }
            let pieData = [];
            let times = 0
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                times += item.cnt
                pieData.push({
                    type: item.modelName,
                    value: item.cnt,
                });
            }

            // sort by count
            pieData.sort((a, b) => b.value - a.value);
            spec_pie.title.subtext = `${intl.get('pages.Detail.total')}: ${renderNumber(times)}`;
            spec_pie.data[0].values = pieData;

            pieChart.updateSpec(spec_pie);
            pieChart.reLayout();
        } else {
            showError(message);
        }
        setLoading(false);
    };
    const initLineChart = async () => {
        let chart = modelDataChart;
        if (!modelDataChart) {
            chart = new VChart(spec_line, { dom: 'model_data' });
            setLineChart(chart)
            chart.on("legendItemHover", e => {
                time = {}
                if (!legends || !legends.length) {
                    legends.push(...JSON.parse(JSON.stringify(chart.getLegendSelectedDataByIndex())))
                }
                const label = e?.event?.detail?.data?.label
                chart.setLegendSelectedDataByIndex(0, [label]);
            })
            chart.on("legendItemUnHover", e => {
                time = {}
                chart.setLegendSelectedDataByIndex(0, legends);
            })
            // chart.on("legendItemClick", e => {
            //     const label = e?.event?.detail?.data?.label
            //     if (!legendSelected[label]) {
            //         legendSelected[label] = 1
            //     } else {
            //         legendSelected[label]++
            //     }
            //     chart.setLegendSelectedDataByIndex(0, Object.keys(legendSelected).filter(item => (legendSelected[item] / 2) % 2 == 1));
            // });
            setModelDataChart(chart);
            chart.renderAsync();
        }
        setLoading(true);

        let url = '';
        if (isAdminUser) {
            url = `api/log/getDailyUsageStatsByDimension?dimension=model`;
        } else {
            url = `/api/log/self/getDailyUsageStatsByDimension?dimension=model`;
        }
        const res = await API.get(url);
        const {success, message, data} = res.data;
        if (success) {
            if (data.length === 0) {
                data.push({
                    count: 0,
                    model_name: `${intl.get('pages.Detail.no-data')}`,
                    quota: 0,
                    created_at: now.getTime() / 1000,
                });
            }
            let line_data = [];
            let totalConsum = 0
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                totalConsum += item.sumUsd
                line_data.push({
                    Time: item.date,
                    Model: item.modelName,
                    Usage: item.sumUsd,
                });
                lineData.push({
                    Time: item.date,
                    Model: item.modelName,
                    Usage: item.sumUsd,
                })
            }
            spec_line.title.subtext = `${intl.get('pages.Detail.total')}: $${Number(totalConsum).toFixed(2)}`;
            spec_line.data[0].values = line_data;
            chart.updateSpec(spec_line);
            chart.reLayout();

            // const currentData = spec_line.series[0].data;
            // spec_line.series[0].data = currentData.map(value => value * -1);
        } else {
            showError(message);
        }
        setLoading(false);
    };
    Date.prototype.format = function(fmt = "yyyy-MM-dd"){
        var o = {
            "M+" : this.getMonth()+1,                 //月份
            "d+" : this.getDate(),                    //日
            "h+" : this.getHours(),                   //小时
            "m+" : this.getMinutes(),                 //分
            "s+" : this.getSeconds(),                 //秒
            "q+" : Math.floor((this.getMonth()+3)/3), //季度
            "S"  : this.getMilliseconds()             //毫秒
        };

        if(/(y+)/.test(fmt)){
            fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
        }

        for(var k in o){
            if(new RegExp("("+ k +")").test(fmt)){
                fmt = fmt.replace(
                    RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
            }
        }
        return fmt;
    }
    // 消耗的
    const [totalQuota, setTotalQuota] = useState({})
    const totayUsed = useCallback(async () => {
        const today = new Date();
        // const {data} = await API.get(`/api/log/?start_timestamp=${new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime() / 1000}&end_timestamp=${new Date(today.getFullYear(), today.getMonth(), today.getDate(), 15).getTime() / 1000 - 1}`)
        // console.warn(">>>>>>>>>", new Date(today.getFullYear(), today.getMonth(), today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 15))
        let url
        if (isAdminUser) {
            url = `/api/log/getDailyUsageStatsByDimension?dimension=user`;
        } else {
            url = `/api/log/self/getDailyUsageStatsByDimension?dimension=user`;
        }
        const {data} = await API.get(url)
        // /api/log/getDailyUsageStatsByDimension?dimension=user&username=lecjy
        if (data.success) {
            for (let item of data.data) {
                if (item.date === today.format()) {
                    setTodayConsume(item.sumUsd)
                    break
                }
            }
        }
    })
    const initBalance = useCallback(async () => {
        const {data} = await API.get("/api/user/self")
        if (data.success) {
            setTotalQuota(data.data.quota)
            setGroup(data.data.group)
            // setAvaiableBanlance(Number((((data.data.quota - data.data.used_quota) / data.data.quota) * 100).toFixed(2)))
            // setTotal_consume(Number((data.data.used_quota / data.data.quota) * 100).toFixed(2))
            setAvaiableBanlance(Number(data.data.quota / 500000).toFixed(2))
            setTotal_consume(Number(data.data.used_quota / 500000).toFixed(2))
        }
    }, [])
    useEffect(() => {
        initBalance()
        totayUsed()
        initPieChart()
        initLineChart()
    }, [])

    return (
        <Layout>
            <Layout.Header>
                <h2>{intl.get('detail')}</h2>
            </Layout.Header>
            <Layout.Content>
                <Layout.Header>
                    <h4 style={{margin: '10px 0 0 15px'}}>{intl.get('pages.Detail.balance-usage')}</h4>
                </Layout.Header>
                <StatisticsWapper>
                    <div className='item'>
                        <div className='show'>
                            <span>{intl.get('pages.Detail.available-balance')}</span>
                            <span> ${avaiableBanlance}</span>
                        </div>
                        <div className='base'>
                            <span>{intl.get('pages.Detail.topUpBalance')}</span>
                            <span> ${Multiplier[group] * avaiableBanlance}</span>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='show'>
                            <span>{intl.get('pages.Detail.current-consumption')}</span>
                            <span> ${todayConsume}</span>
                        </div>
                        <div className='base'>
                            <span>{intl.get('pages.Detail.dailyTopUpUsage') + " "}</span>
                            <span> ${Number(Multiplier[group] * todayConsume).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='show'>
                            <span>{intl.get('pages.Detail.total-consumption') + " "}</span>
                            <span> ${total_consume}</span>
                        </div>
                        <div className='base'>
                            <span>{intl.get('pages.Detail.accumulateTopUpUsage')}</span>
                            <span> ${Multiplier[group] * total_consume}</span>
                        </div>
                    </div>
                </StatisticsWapper>
                <Spin spinning={loading}>
                    <div style={{height: 500}}>
                        <div id='model_pie' style={{width: '100%', minWidth: 100}}></div>
                    </div>
                    <div style={{height: 500}}>
                        <div id='model_data' style={{width: '100%', minWidth: 100}}></div>
                    </div>
                </Spin>
            </Layout.Content>
        </Layout>
    );
};

export default Detail;
