import React, { useEffect, useState } from 'react';
import { API, isAdmin, showError, showSuccess } from '../helpers';
import { Button, Modal, Popconfirm, Popover, Table, Tag, Form, Tooltip, Space } from '@douyinfe/semi-ui';
import { ITEMS_PER_PAGE } from '../constants';
import { renderGroup, renderNumber, renderQuota, renderText, stringToColor } from '../helpers/render';
import AddUser from '../pages/User/AddUser';
import EditUser from '../pages/User/EditUser';

import { intl } from '../lang';
function renderRole(role) {
	switch (role) {
		case 1:
			return <Tag size='large'>{intl.get('components.UsersTable.common-user')}</Tag>;
		case 10:
			return (
				<Tag color='yellow' size='large'>
					{intl.get('components.UsersTable.admin')}
				</Tag>
			);
		case 100:
			return (
				<Tag color='orange' size='large'>
					{intl.get('components.UsersTable.super-admin')}
				</Tag>
			);
		default:
			return (
				<Tag color='red' size='large'>
					{intl.get('components.UsersTable.unknown-user')}
				</Tag>
			);
	}
}

const UsersTable = () => {
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: `${intl.get('components.UsersTable.username')}`,
			dataIndex: 'username',
		},
		{
			title: `${intl.get('components.UsersTable.group')}`,
			dataIndex: 'group',
			render: (text, record, index) => {
				return <div>{renderGroup(text)}</div>;
			},
		},
		{
			title: `${intl.get('components.UsersTable.info')}`,
			dataIndex: 'info',
			render: (text, record, index) => {
				return (
					<div>
						<Space spacing={1}>
							<Tooltip content={intl.get('components.UsersTable.residual-credit')}>
								<Tag color='white' size='large'>
									{renderQuota(record.quota)}
								</Tag>
							</Tooltip>
							<Tooltip content={intl.get('components.UsersTable.used-credit')}>
								<Tag color='white' size='large'>
									{renderQuota(record.used_quota)}
								</Tag>
							</Tooltip>
							<Tooltip content={intl.get('components.UsersTable.call-count')}>
								<Tag color='white' size='large'>
									{renderNumber(record.request_count)}
								</Tag>
							</Tooltip>
						</Space>
					</div>
				);
			},
		},
		{
			title: `${intl.get('components.UsersTable.invite-info')}`,
			dataIndex: 'invite',
			render: (text, record, index) => {
				return (
					<div>
						<Space spacing={1}>
							<Tooltip content={intl.get('components.UsersTable.invite-count')}>
								<Tag color='white' size='large'>
									{renderNumber(record.aff_count)}
								</Tag>
							</Tooltip>
							<Tooltip content={intl.get('components.UsersTable.invite-total-revenue')}>
								<Tag color='white' size='large'>
									{renderQuota(record.aff_history_quota)}
								</Tag>
							</Tooltip>
							<Tooltip content={intl.get('components.UsersTable.invite-user-ID')}>
								{record.inviter_id === 0 ? (
									<Tag color='white' size='large'>
										{intl.get('components.UsersTable.null')}
									</Tag>
								) : (
									<Tag color='white' size='large'>
										{record.inviter_id}
									</Tag>
								)}
							</Tooltip>
						</Space>
					</div>
				);
			},
		},
		{
			title: `${intl.get('components.UsersTable.role')}`,
			dataIndex: 'role',
			render: (text, record, index) => {
				return <div>{renderRole(text)}</div>;
			},
		},
		{
			title: `${intl.get('components.UsersTable.status')}`,
			dataIndex: 'status',
			render: (text, record, index) => {
				return <div>{renderStatus(text)}</div>;
			},
		},
		{
			title: '',
			dataIndex: 'operate',
			render: (text, record, index) => (
				<div>
					{record.DeletedAt !== null ? (
						<></>
					) : (
						<>
							<Popconfirm
								title={intl.get('components.UsersTable.yes')}
								okType={'warning'}
								onConfirm={() => {
									manageUser(record.username, 'promote', record);
								}}
							>
								<Button theme='light' type='warning' style={{ marginRight: 1 }}>
									{intl.get('components.UsersTable.promote')}
								</Button>
							</Popconfirm>
							<Popconfirm
								title={intl.get('components.UsersTable.yes')}
								okType={'warning'}
								onConfirm={() => {
									manageUser(record.username, 'demote', record);
								}}
							>
								<Button theme='light' type='secondary' style={{ marginRight: 1 }}>
									{intl.get('components.UsersTable.demotion')}
								</Button>
							</Popconfirm>
							{record.status === 1 ? (
								<Button
									theme='light'
									type='warning'
									style={{ marginRight: 1 }}
									onClick={async () => {
										manageUser(record.username, 'disable', record);
									}}
								>
									{intl.get('components.UsersTable.disable')}
								</Button>
							) : (
								<Button
									theme='light'
									type='secondary'
									style={{ marginRight: 1 }}
									onClick={async () => {
										manageUser(record.username, 'enable', record);
									}}
									disabled={record.status === 3}
								>
									{intl.get('components.UsersTable.enable')}
								</Button>
							)}
							<Button
								theme='light'
								type='tertiary'
								style={{ marginRight: 1 }}
								onClick={() => {
									setEditingUser(record);
									setShowEditUser(true);
								}}
							>
								{intl.get('components.UsersTable.edit')}
							</Button>
						</>
					)}
					<Popconfirm
						title={intl.get('components.UsersTable.are-you-sure-you-want-to-delete-this-user')}
						content={intl.get('components.UsersTable.hard-delete')}
						okType={'danger'}
						position={'left'}
						onConfirm={() => {
							manageUser(record.username, 'delete', record).then(() => {
								removeRecord(record.id);
							});
						}}
					>
						<Button theme='light' type='danger' style={{ marginRight: 1 }}>
							{intl.get('components.UsersTable.delete')}
						</Button>
					</Popconfirm>
				</div>
			),
		},
	];

	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activePage, setActivePage] = useState(1);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [searching, setSearching] = useState(false);
	const [userCount, setUserCount] = useState(ITEMS_PER_PAGE);
	const [showAddUser, setShowAddUser] = useState(false);
	const [showEditUser, setShowEditUser] = useState(false);
	const [editingUser, setEditingUser] = useState({
		id: undefined,
	});

	const setCount = (data) => {
		if (data.length >= activePage * ITEMS_PER_PAGE) {
			setUserCount(data.length + 1);
		} else {
			setUserCount(data.length);
		}
	};

	const removeRecord = (key) => {
		console.log(key);
		let newDataSource = [...users];
		if (key != null) {
			let idx = newDataSource.findIndex((data) => data.id === key);

			if (idx > -1) {
				newDataSource.splice(idx, 1);
				setUsers(newDataSource);
			}
		}
	};

	const loadUsers = async (startIdx) => {
		const res = await API.get(`/api/user/?p=${startIdx}`);
		const { success, message, data } = res.data;
		if (success) {
			if (startIdx === 0) {
				setUsers(data);
				setCount(data);
			} else {
				let newUsers = users;
				newUsers.push(...data);
				setUsers(newUsers);
				setCount(newUsers);
			}
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const onPaginationChange = (e, { activePage }) => {
		(async () => {
			if (activePage === Math.ceil(users.length / ITEMS_PER_PAGE) + 1) {
				// In this case we have to load more data and then append them.
				await loadUsers(activePage - 1);
			}
			setActivePage(activePage);
		})();
	};

	useEffect(() => {
		loadUsers(0)
			.then()
			.catch((reason) => {
				showError(reason);
			});
	}, []);

	const manageUser = async (username, action, record) => {
		const res = await API.post('/api/user/manage', {
			username,
			action,
		});
		const { success, message } = res.data;
		if (success) {
			showSuccess(`${intl.get('components.UsersTable.operation-completed-successfully')}`);
			let user = res.data.data;
			let newUsers = [...users];
			if (action === 'delete') {
			} else {
				record.status = user.status;
				record.role = user.role;
			}
			setUsers(newUsers);
		} else {
			showError(message);
		}
	};

	const renderStatus = (status) => {
		switch (status) {
			case 1:
				return <Tag size='large'>{intl.get('components.UsersTable.activated')}</Tag>;
			case 2:
				return (
					<Tag size='large' color='red'>
						{intl.get('components.UsersTable.is-banned')}
					</Tag>
				);
			default:
				return (
					<Tag size='large' color='grey'>
						{intl.get('components.UsersTable.unknown-state')}
					</Tag>
				);
		}
	};

	const searchUsers = async () => {
		if (searchKeyword === '') {
			// if keyword is blank, load files instead.
			await loadUsers(0);
			setActivePage(1);
			return;
		}
		setSearching(true);
		const res = await API.get(`/api/user/search?keyword=${searchKeyword}`);
		const { success, message, data } = res.data;
		if (success) {
			setUsers(data);
			setActivePage(1);
		} else {
			showError(message);
		}
		setSearching(false);
	};

	const handleKeywordChange = async (value) => {
		setSearchKeyword(value.trim());
	};

	const sortUser = (key) => {
		if (users.length === 0) return;
		setLoading(true);
		let sortedUsers = [...users];
		sortedUsers.sort((a, b) => {
			return ('' + a[key]).localeCompare(b[key]);
		});
		if (sortedUsers[0].id === users[0].id) {
			sortedUsers.reverse();
		}
		setUsers(sortedUsers);
		setLoading(false);
	};

	const handlePageChange = (page) => {
		setActivePage(page);
		if (page === Math.ceil(users.length / ITEMS_PER_PAGE) + 1) {
			// In this case we have to load more data and then append them.
			loadUsers(page - 1).then((r) => {});
		}
	};

	const pageData = users.slice((activePage - 1) * ITEMS_PER_PAGE, activePage * ITEMS_PER_PAGE);

	const closeAddUser = () => {
		setShowAddUser(false);
	};

	const closeEditUser = () => {
		setShowEditUser(false);
		setEditingUser({
			id: undefined,
		});
	};

	const refresh = async () => {
		if (searchKeyword === '') {
			await loadUsers(activePage - 1);
		} else {
			await searchUsers();
		}
	};

	return (
		<>
			<AddUser refresh={refresh} visible={showAddUser} handleClose={closeAddUser}></AddUser>
			<EditUser refresh={refresh} visible={showEditUser} handleClose={closeEditUser} editingUser={editingUser}></EditUser>
			<Form onSubmit={searchUsers}>
				<Form.Input
					label={intl.get('components.UsersTable.keyword')}
					icon='search'
					field='keyword'
					iconPosition='left'
					placeholder={intl.get('components.UsersTable.search-user-info')}
					value={searchKeyword}
					loading={searching}
					onChange={(value) => handleKeywordChange(value)}
				/>
			</Form>

			<Table
				columns={columns}
				dataSource={pageData}
				pagination={{
					currentPage: activePage,
					pageSize: ITEMS_PER_PAGE,
					total: userCount,
					// showSizeChanger: true,
					// pageSizeOpts: [10, 20, 50, 100, 500],
					onPageChange: handlePageChange,
				}}
				loading={loading}
			/>
			<Button
				theme='light'
				type='primary'
				style={{ marginRight: 8 }}
				onClick={() => {
					setShowAddUser(true);
				}}
			>
				{intl.get('components.UsersTable.add-user')}
			</Button>
		</>
	);
};

export default UsersTable;
