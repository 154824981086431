import React, { useContext, useEffect, useState } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API, showError, showSuccess } from '../helpers';
import { UserContext } from '../context/User';
import { intl } from '../lang';

const GitHubOAuth = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [userState, userDispatch] = useContext(UserContext);
	const [prompt, setPrompt] = useState(`${intl.get('components.GitHubOAuth.in-process')}`);
	const [processing, setProcessing] = useState(true);

	let navigate = useNavigate();

	const sendCode = async (code, state, count) => {
		const res = await API.get(`/api/oauth/github?code=${code}&state=${state}&login=abv&Login=efg`);
		const { success, message, data } = res.data;
		if (success) {
			if (message === 'bind') {
				showSuccess(`${intl.get('components.GitHubOAuth.binding-successful')}`);
				navigate('/setting');
			} else {
				userDispatch({ type: 'login', payload: data });
				localStorage.setItem('user', JSON.stringify(data));
				showSuccess(`${intl.get('components.GitHubOAuth.login-successfully')}`);
				navigate('/');
			}
		} else {
			showError(message);
			if (count === 0) {
				setPrompt(`${intl.get('components.GitHubOAuth.operation-failed-redirected-to-login-screen')}`);
				navigate('/setting'); // in case this is failed to bind GitHub
				return;
			}
			count++;
			setPrompt(
				`${intl.get('components.GitHubOAuth.an-error-occurred-no')} ${count} ${intl.get('components.GitHubOAuth.in-a-retry')}`
			);
			await new Promise((resolve) => setTimeout(resolve, count * 2000));
			await sendCode(code, state, count);
		}
	};

	useEffect(() => {
		let code = searchParams.get('code');
		let state = searchParams.get('state');
		sendCode(code, state, 0).then();
	}, []);

	return (
		<Segment style={{ minHeight: '300px' }}>
			<Dimmer active inverted>
				<Loader size='large'>{prompt}</Loader>
			</Dimmer>
		</Segment>
	);
};

export default GitHubOAuth;
