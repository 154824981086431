import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './Register';
import PasswordResetForm from './PasswordResetForm';
import { useState } from 'react';
import { useStore } from '../../store/index';
import { intl } from '../../lang';
const description = {
	de: {
		title: 'MaxAPI - 100% KI-API-Effizienz zu 50% der Kosten freisetzen',
		context:
			'Registrieren Sie sich in nur 10 Sekunden, um erstklassige KI-API-Leistungen wie GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs... zu 50% der Kosten zu genießen. Vertrauen Sie uns, eine 10-Sekunden-Registrierung wird Sie die MaxAPI-Plattform langfristig schätzen und nutzen lassen. — Basierend auf 280 echten Nutzerbewertungen (Projektleiter und Einzelentwickler).',
	},
	en: {
		title: 'MaxAPI - Unlocking 100% AI API Efficiency at 50% Cost',
		context:
			'Register in just 10 seconds to enjoy top AI API performance like GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs... at 50% cost. Trust us, a 10-second registration will make you appreciate and use the MaxAPI platform long-term. — From 280 real user reviews (team project leaders and individual developers).',
	},
	es: {
		title: 'MaxAPI - Desbloqueando el 100% de eficiencia de API de IA al 50% de costo',
		context:
			'Regístrese en solo 10 segundos para disfrutar del rendimiento superior de API de IA como GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs... al 50% de costo. Confíe en nosotros, un registro de 10 segundos le hará apreciar y usar la plataforma MaxAPI a largo plazo. — Basado en 280 opiniones reales de usuarios (líderes de proyectos y desarrolladores individuales).',
	},
	fr: {
		title: 'MaxAPI - Libérer 100% d\'efficacité de l\'API IA à 50% du coût',
		context:
			"Inscrivez-vous en seulement 10 secondes pour profiter des meilleures performances d'API IA comme GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs... à 50% du coût. Faites-nous confiance, une inscription de 10 secondes vous fera apprécier et utiliser la plateforme MaxAPI à long terme. — D'après les avis de 280 utilisateurs réels (chefs de projet et développeurs individuels).",
	},
	ja: {
		title: 'MaxAPI - 50%のコストで100%のAI API効率を実現',
		context:
			'たった10秒の登録で、GPT-4o、DALLE3、Midjourney、Claude-3.5、GPTs...などのトップAI API性能を50%のコストで享受できます。信じてください、10秒の登録でMaxAPIプラットフォームを長期間使用し続けることになるでしょう。 — 280名のユーザー（チームプロジェクトリーダーと個人開発者）からの実際のレビューより。',
	},
	ko: {
		title: 'MaxAPI - 50%의 비용으로 100% AI API 성능 발휘',
		context: '단 10초만에 등록하고 GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs 등 최상급 AI API 성능을 50% 비용으로 누리세요. 믿어보세요, 10초의 등록이 MaxAPI 플랫폼을 찬양하고 오랫동안 사용할 수 있게 할 것입니다. —— 280명의 사용자(팀 프로젝트 책임자 및 개인 개발자)로부터 받은 진솔한 평가입니다.',
	},
	'zh-tw': {
		title: 'MaxAPI - 以50%的成本，激發100%的AI API效能',
		context:
			'只需10秒鐘註冊，您就可以以50%成本享受GPT-4o，DALLE3、Midjourney、Claude-3.5、GPTs…等頂級AI API效能，相信我們，10秒鐘的註冊會讓你欣賞並長期使用MaxAPI平台。——來自280名用戶（團隊項目負責人與個人開發者）的真實評價。',
	},
	zh: {
		title: 'MaxAPI - 以50%的成本，激发100%的AI API效能',
		context:
			'只需10秒钟注册，您就可以以50%成本享受GPT-4o，DALLE3、Midjourney、Claude-3.5、GPTs…等顶级AI API效能，相信我们，10秒钟的注册会让你欣赏并长期使用MaxAPI平台。——来自280名用户（团队项目负责人与个人开发者）的真实评价。',
	},
};
const key = localStorage.getItem('lang') ?? 'en';
const LoginWarpper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	.header-container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px;
	}
	.context {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		--color: #e7e7e7;
		.left-box {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 24px;
			flex: 1;
			width: 0;
			min-width: 400px;
			height: 100%;
			padding: 0px 24px;
			background-image: linear-gradient(to right, rgb(225 48 96), rgb(0 71 207));
			box-shadow: 1px 1px 4px var(--color), 1px -1px 4px var(--color), -1px 0px 4px var(--color);
			.logo {
				width: 500px;
			}

			.title {
				font-size: 32px;
				font-weight: 500;
				text-align: center;
			}
		}
		.right-container {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			flex: 1;
			width: 0;
			border-top-right-radius: 0.375rem /* 6px */;
			border-bottom-right-radius: 0.375rem /* 6px */;
			transition: all 0.5s;

			box-shadow: 1px 1px 4px var(--color), 1px -1px 4px var(--color), -1px 0px 4px var(--color);
			.right-form {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-left: 4rem;
				padding-right: 4rem;
				overflow: hidden;
				width: 100%;
				height: 100%;
				.right-item {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
`;
const Login = ({ defaultCurrent }) => {
	const [current, setCurrent] = useState(defaultCurrent ?? 0);
	const { isDark } = useStore();
	return (
		<LoginWarpper>
			<div className='header-container'>
				<Link to='/'>
					<img src={isDark ? './app-logo.png' : './logo-hd.png'} alt='logo' style={{
						height: '32px'
					}} />
				</Link>
			</div>
			<div class='context'>
				<div class='left-box'>
					<img className='logo' src='./app-logo.png' />
					<h1 className='title'>{description[key].title}</h1>
				</div>
				<div class='right-container'>
					<div class='right-form'>
						{current === 0 && (
							<div className='right-item'>
								<LoginForm className='right-item' setCurrent={setCurrent} />
							</div>
						)}
						{current === 1 && (
							<div className='right-item'>
								<RegisterForm className='right-item' setCurrent={setCurrent} />
							</div>
						)}
						{current === 2 && (
							<div className='right-item'>
								<PasswordResetForm className='right-item' setCurrent={setCurrent} />
							</div>
						)}
						<div style={{position: 'absolute', bottom:'10px', display: 'flex',gap: '6px', justifyContent: 'space-between', padding:"4px 0", fontSize:"14px", color: '#52C8FC'}}>
							<Link to='/terms'>{intl.get('components.PasswordResetForm.terms')}</Link>
							|
							<Link to='/privacy'>{intl.get('components.PasswordResetForm.privacy')}</Link>
						</div>
					</div>
				</div>
			</div>
		</LoginWarpper>
	);
};

export default Login;
