import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from './components/Loading';
import User from './pages/User';
import { PrivateRoute } from './components/PrivateRoute';
import NotFound from './pages/NotFound';
import Setting from './pages/Setting';
import EditUser from './pages/User/EditUser';
import { API, getLogo, getSystemName, showError } from './helpers';
import PasswordResetForm from './components/PasswordResetForm';
import GitHubOAuth from './components/GitHubOAuth';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import { UserContext } from './context/User';
import { StatusContext } from './context/Status';
import Channel from './pages/Channel';
import Token from './pages/Token';
import EditChannel from './pages/Channel/EditChannel';
import Redemption from './pages/Redemption';
import TopUp from './pages/TopUp';
import Log from './pages/Log';
import APIDocument from './pages/API';
import Chat from './pages/Chat';
import Midjourney from './pages/Midjourney';
import { intl } from './lang';
import { BrowserRouter } from 'react-router-dom';
import { DefaultLayout, HomeLayout } from './layouts';
import Detail from './pages/Detail';
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import { StoreContext } from './store';

import zh_CN from '@douyinfe/semi-ui/lib/es/locale/source/zh_CN';
import en_US from '@douyinfe/semi-ui/lib/es/locale/source/en_US';
import ko_KR from '@douyinfe/semi-ui/lib/es/locale/source/ko_KR';
import ja_JP from '@douyinfe/semi-ui/lib/es/locale/source/ja_JP';
import zh_TW from '@douyinfe/semi-ui/lib/es/locale/source/zh_TW';
import es from '@douyinfe/semi-ui/lib/es/locale/source/es';
import de from '@douyinfe/semi-ui/lib/es/locale/source/de';
import fr from '@douyinfe/semi-ui/lib/es/locale/source/fr';
import { LocaleProvider } from '@douyinfe/semi-ui';
import Login from './pages/Login';
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));

const keyMap = {
	de,
	en: en_US,
	es,
	fr,
	ja: ja_JP,
	ko: ko_KR,
	'zh-tw': zh_TW,
	zh: zh_CN,
};

const description = {
	de: {
		title: 'MaxAPI - 100% KI-API-Effizienz zu 50% der Kosten freisetzen',
		context:
			'Registrieren Sie sich in nur 10 Sekunden, um erstklassige KI-API-Leistungen wie GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs... zu 50% der Kosten zu genießen. Vertrauen Sie uns, eine 10-Sekunden-Registrierung wird Sie die MaxAPI-Plattform langfristig schätzen und nutzen lassen. — Basierend auf 280 echten Nutzerbewertungen (Projektleiter und Einzelentwickler).',
	},
	en: {
		title: 'MaxAPI - Unlocking 100% AI API Efficiency at 50% Cost',
		context:
			'Register in just 10 seconds to enjoy top AI API performance like GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs... at 50% cost. Trust us, a 10-second registration will make you appreciate and use the MaxAPI platform long-term. — From 280 real user reviews (team project leaders and individual developers).',
	},
	es: {
		title: 'MaxAPI - Desbloqueando el 100% de eficiencia de API de IA al 50% de costo',
		context:
			'Regístrese en solo 10 segundos para disfrutar del rendimiento superior de API de IA como GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs... al 50% de costo. Confíe en nosotros, un registro de 10 segundos le hará apreciar y usar la plataforma MaxAPI a largo plazo. — Basado en 280 opiniones reales de usuarios (líderes de proyectos y desarrolladores individuales).',
	},
	fr: {
		title: 'MaxAPI - Libérer 100% d\'efficacité de l\'API IA à 50% du coût',
		context:
			"Inscrivez-vous en seulement 10 secondes pour profiter des meilleures performances d'API IA comme GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs... à 50% du coût. Faites-nous confiance, une inscription de 10 secondes vous fera apprécier et utiliser la plateforme MaxAPI à long terme. — D'après les avis de 280 utilisateurs réels (chefs de projet et développeurs individuels).",
	},
	ja: {
		title: 'MaxAPI - 50%のコストで100%のAI API効率を実現',
		context:
			'たった10秒の登録で、GPT-4o、DALLE3、Midjourney、Claude-3.5、GPTs...などのトップAI API性能を50%のコストで享受できます。信じてください、10秒の登録でMaxAPIプラットフォームを長期間使用し続けることになるでしょう。 — 280名のユーザー（チームプロジェクトリーダーと個人開発者）からの実際のレビューより。',
	},
	ko: {
		title: 'MaxAPI - 50%의 비용으로 100% AI API 성능 발휘',
		context: '단 10초만에 등록하고 GPT-4o, DALLE3, Midjourney, Claude-3.5, GPTs 등 최상급 AI API 성능을 50% 비용으로 누리세요. 믿어보세요, 10초의 등록이 MaxAPI 플랫폼을 찬양하고 오랫동안 사용할 수 있게 할 것입니다. —— 280명의 사용자(팀 프로젝트 책임자 및 개인 개발자)로부터 받은 진솔한 평가입니다.',
	},
	'zh-tw': {
		title: 'MaxAPI - 以50%的成本，激發100%的AI API效能',
		context:
			'只需10秒鐘註冊，您就可以以50%成本享受GPT-4o，DALLE3、Midjourney、Claude-3.5、GPTs…等頂級AI API效能，相信我們，10秒鐘的註冊會讓你欣賞並長期使用MaxAPI平台。——來自280名用戶（團隊項目負責人與個人開發者）的真實評價。',
	},
	zh: {
		title: 'MaxAPI - 以50%的成本，激发100%的AI API效能',
		context:
			'只需10秒钟注册，您就可以以50%成本享受GPT-4o，DALLE3、Midjourney、Claude-3.5、GPTs…等顶级AI API效能，相信我们，10秒钟的注册会让你欣赏并长期使用MaxAPI平台。——来自280名用户（团队项目负责人与个人开发者）的真实评价。',
	},
};
const key = localStorage.getItem('lang') ?? 'en';
function App() {
	const [userState, userDispatch] = useContext(UserContext);
	const [statusState, statusDispatch] = useContext(StatusContext);
	const loadUser = () => {
		let user = localStorage.getItem('user');
		if (user) {
			let data = JSON.parse(user);
			userDispatch({ type: 'login', payload: data });
		}
	};
	const loadStatus = async () => {
		const res = await API.get('/api/status');
		const { success, data } = res.data;
		if (success) {
			localStorage.setItem('status', JSON.stringify(data));
			statusDispatch({ type: 'set', payload: data });
			localStorage.setItem('system_name', data.system_name);
			localStorage.setItem('logo', data.logo);
			localStorage.setItem('footer_html', data.footer_html);
			localStorage.setItem('quota_per_unit', data.quota_per_unit);
			localStorage.setItem('display_in_currency', data.display_in_currency);
			localStorage.setItem('enable_drawing', data.enable_drawing);
			localStorage.setItem('enable_data_export', data.enable_data_export);
			localStorage.setItem('data_export_default_time', data.data_export_default_time);
			if (data.chat_link) {
				localStorage.setItem('chat_link', data.chat_link);
			} else {
				localStorage.removeItem('chat_link');
			}
		} else {
			showError(`${intl.get('App.unable-to-connect-server')}`);
		}
	};

	useEffect(() => {
		loadUser();
		loadStatus().then();
		let systemName = getSystemName();
		if (systemName) {
			document.title = systemName;
		}
		let logo = getLogo();
		if (logo) {
			let linkElement = document.querySelector("link[rel~='icon']");
			if (linkElement) {
				linkElement.href = logo;
			}
		}

		// 处理html
		const metaDescription = document.querySelector('meta[name="description"]');
		metaDescription.content = description[key].context;

		const metaDescriptionOg = document.querySelector('meta[property="og:description"]');
		metaDescriptionOg.content = description[key].context;

		const metaDescriptionOgTitle = document.querySelector('meta[property="og:title"]');
		metaDescriptionOgTitle.content = description[key].title;

		const metaDescriptionOgTwitterTitle = document.querySelector('meta[property="twitter:title"]');
		metaDescriptionOgTwitterTitle.content = description[key].title;

		const metaDescriptionTwitterOg = document.querySelector('meta[property="twitter:description"]');
		metaDescriptionTwitterOg.content = description[key].context;

		document.title = description[key].title;
	}, []);

	useEffect(() => {
		var phoneWidth = parseInt(window.screen.width) / 1920;
		const viewport = document.createElement('meta');
		viewport.name = 'viewport';
		viewport.content = `width=device-width, initial-scale=${phoneWidth}`;
		document.head.appendChild(viewport);
		return () => {
			document.head.removeChild(viewport);
		};
	}, []);
	const [store, setStore] = useState({
		'theme-mode': localStorage.getItem('theme-mode')
	});
	useEffect(() => {
		if(localStorage.getItem('theme-mode') === 'dark') {
			document.body.setAttribute('theme-mode', 'dark');
		}
	}, [])
	const setItem = (key, value) => {
		setStore({
			...store,
			[key]: value,
		});
	};
	return (
		<LocaleProvider locale={keyMap[key]}>
			<StoreContext.Provider
				value={{
					store,
					setItem
				}}
			>
				<BrowserRouter>
					<Routes>
						<Route
							path='/'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<HomeLayout>
										<Home />
									</HomeLayout>
								</Suspense>
							}
						/>
						<Route
							path='/privacy'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<HomeLayout>
										<Privacy />
									</HomeLayout>
								</Suspense>
							}
						/>
						<Route
							path='/terms'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<HomeLayout>
										<Terms />
									</HomeLayout>
								</Suspense>
							}
						/>
						<Route
							path='/channel'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Channel />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/channel/edit/:id'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<EditChannel />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/channel/add'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<EditChannel />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/api-keys'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Token />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/redemption'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Redemption />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/user'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<User />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/user/edit/:id'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<EditUser />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/user/edit'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<EditUser />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/user/reset'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<PasswordResetConfirm />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/login'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<Login />
								</Suspense>
							}
						/>
						<Route
							path='/register'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<Login defaultCurrent={1} />
								</Suspense>
							}
						/>
						<Route
							path='/reset'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<PasswordResetForm />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/oauth/github'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<GitHubOAuth />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/setting'
							element={
								<PrivateRoute>
									<Suspense fallback={<Loading></Loading>}>
										<DefaultLayout>
											<Setting />
										</DefaultLayout>
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path='/topup'
							element={
								<PrivateRoute>
									<Suspense fallback={<Loading></Loading>}>
										<DefaultLayout>
											<TopUp />
										</DefaultLayout>
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path='/log'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Log />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/overview'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Detail />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/midjourney'
							element={
								<PrivateRoute>
									<DefaultLayout>
										<Midjourney />
									</DefaultLayout>
								</PrivateRoute>
							}
						/>
						<Route
							path='/about'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<About />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route
							path='/chat'
							element={
								<Suspense fallback={<Loading></Loading>}>
									<DefaultLayout>
										<Chat />
									</DefaultLayout>
								</Suspense>
							}
						/>
						<Route path='*' element={<NotFound />} />
					</Routes>
				</BrowserRouter>
			</StoreContext.Provider>
		</LocaleProvider>
	);
}

export default App;
