import React, {useCallback, useContext, useEffect, useState} from 'react';
import {API, copy, isAdmin, showError, showSuccess, timestamp2string} from '../helpers';

import {Table, Avatar, Tag, Form, Button, Layout, Select, Popover, Modal, Spin} from '@douyinfe/semi-ui';
import {ITEMS_PER_PAGE, pageSize} from '../constants';
import {renderNumber, renderQuota, stringToColor} from '../helpers/render';
import XLSX from "xlsx";
import FileSaver from 'file-saver';

import {intl} from '../lang';

const {Header} = Layout;

function renderTimestamp(timestamp) {
    return <>{timestamp2string(timestamp)}</>;
}

const colors = [
    'amber',
    'blue',
    'cyan',
    'green',
    'grey',
    'indigo',
    'light-blue',
    'lime',
    'orange',
    'pink',
    'purple',
    'red',
    'teal',
    'violet',
    'yellow',
];

function renderType(type) {
    switch (type) {
        case 1:
            return (
                <Tag color='cyan' size='large'>
                    {' ' + intl.get("components.LogsTable.pay") + ' '}
                </Tag>
            );
        case 2:
            return (
                <Tag color='lime' size='large'>
                    {' ' + intl.get("components.LogsTable.consume") + ' '}
                </Tag>
            );
        case 3:
            return (
                <Tag color='orange' size='large'>
                    {' ' + intl.get("components.LogsTable.manage") + ' '}
                </Tag>
            );
        case 4:
            return (
                <Tag color='purple' size='large'>
                    {' ' + intl.get("components.LogsTable.system") + ' '}
                </Tag>
            );
        default:
            return (
                <Tag color='teal' size='large'>
                    {' ' + intl.get("components.LogsTable.log") + ' '}
                </Tag>
            );
    }
}

const LogsTable = () => {
    const columns = [
        {
            title: `${intl.get("components.LogsTable.time")}`,
            dataIndex: 'timestamp2string',
        },
        ...isAdmin() ? [
            {
                title: `${intl.get("components.LogsTable.channel")}`,
                dataIndex: 'channel',
                className: isAdmin() ? 'tableShow' : 'tableHiddle',
                render: (text, record, index) => {
                    return isAdminUser ? (
                        record.type === 0 || record.type === 2 ? (
                            <Tag color={colors[parseInt(text) % colors.length]} size='large'>
                                {' '}
                                {text}{' '}
                            </Tag>
                        ) : ''
                    ) : '';
                },
            },
            {
                title: `${intl.get("components.LogsTable.user")}`,
                dataIndex: 'username',
                className: isAdmin() ? 'tableShow' : 'tableHiddle',
                render: (text, record, index) => {
                    return isAdminUser ? (
                        <div>
                            <Avatar
                                size='small'
                                color={stringToColor(text)}
                                style={{marginRight: 4}}
                                onClick={() => showUserInfo(record.user_id)}>
                                {typeof text === 'string' && text.slice(0, 1)}
                            </Avatar>
                            {text}
                        </div>
                    ) : '';
                },
            },
        ] : [],
        {
            title: `${intl.get("components.LogsTable.token")}`,
            dataIndex: 'token_name',
            render: (text, record, index) => {
                return record.type === 0 || record.type === 2 ? (
                    <Tag
                        color='grey'
                        size='large'
                        onClick={() => {
                            copyText(text);
                        }}>
                        {' '}
                        {text}{' '}
                    </Tag>
                ) : '';
            },
        },
        {
            title: `${intl.get("components.LogsTable.type")}`,
            dataIndex: 'type',
            render: (text, record, index) => {
                return <div>{renderType(text)}</div>;
            },
        },
        {
            title: `${intl.get("components.LogsTable.model")}`,
            dataIndex: 'model_name',
            render: (text, record, index) => {
                return record.type === 0 || record.type === 2 ? (
                    <Tag
                        color={stringToColor(text)}
                        size='large'
                        onClick={() => {
                            copyText(text);
                        }}>
                        {' '}
                        {text}{' '}
                    </Tag>
                ) : '';
            },
        },
        {
            title: `${intl.get("components.LogsTable.duration_for_view")}`,
            dataIndex: 'duration_for_view',
            render: (text, record, index) => {
                return <div>{<span> {record.duration_for_view ? record.duration_for_view : record.total_duration ? record.total_duration : 0}s </span>}</div>;
            },
        },
        {
            title: `${intl.get("components.LogsTable.stream")}`,
            dataIndex: 'is_stream',
            render: (text, record, index) => {
                return <div>{record.type === 2 ? record.is_stream ? intl.get("components.LogsTable.stream") : intl.get("components.LogsTable.not-stream") : ''}</div>;
            },
        },
        {
            title: `${intl.get("components.LogsTable.hint")}`,
            dataIndex: 'prompt_tokens',
            render: (text, record, index) => {
                return record.type === 0 || record.type === 2 ? <div>{<span> {text ? text : ''} </span>}</div> : <></>;
            },
        },
        {
            title: `${intl.get("components.LogsTable.complement")}`,
            dataIndex: 'completion_tokens',
            render: (text, record, index) => {
                return parseInt(text) > 0 && (record.type === 0 || record.type === 2) ? <div>{<span> {text} </span>}</div> : <></>;
            },
        },
        {
            title: `${intl.get("components.LogsTable.quota")}`,
            dataIndex: 'quota',
            render: (text, record, index) => {
                return record.type === 0 || record.type === 2 ? <div>{renderQuota(text, 6)}</div> : <></>;
            },
        },
        {
            title: `${intl.get("components.LogsTable.content")}`,
            dataIndex: 'content',
            render: (text, record, index) => {
                return <div>{text}</div>;
            },
        },
    ];
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [logCount, setLogCount] = useState(0);
    const [logType, setLogType] = useState(0);
    const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);
    const [statInfo, setStatInfo] = useState({
        rpm: 0,
        tpm: 0,
        mpm: 0,
        quota: 0
    });
    const isAdminUser = isAdmin();
    let now = new Date();
    // 初始化start_timestamp为前一天
    const [inputs, setInputs] = useState({
        username: '',
        token_name: '',
        model_name: '',
        start_timestamp: timestamp2string(now.getTime() / 1000 - 86400),
        end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
        channel: '',
    });
    const {username, token_name, model_name, start_timestamp, end_timestamp, channel} = inputs;

    const handleInputChange = (value, name) => {
        setInputs((inputs) => ({...inputs, [name]: value}));
    };

    const showUserInfo = async (userId) => {
        if (!isAdminUser) {
            return;
        }
        const res = await API.get(`/api/user/${userId}`);
        const {success, message, data} = res.data;
        if (success) {
            Modal.info({
                title: `${intl.get("components.LogsTable.userinfo")}`,
                content: (
                    <div style={{padding: 12}}>{data}
                        <p>{intl.get("components.LogsTable.username") + ": " + data.username}</p>
                        <p>{intl.get("components.LogsTable.balance") + ": " + renderQuota(data.quota)}</p>
                        <p>{intl.get("components.LogsTable.used-amount") + ": " + renderQuota(data.used_quota)}</p>
                        <p>{intl.get("components.LogsTable.requests-count") + ": " + renderNumber(data.request_count)}</p>
                    </div>
                ),
                centered: true,
            });
        } else {
            showError(message);
        }
    };
    const guid = () => {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }

        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }
    const setLogsFormat = (logs) => {
        for (let i = 0; i < logs.length; i++) {
            logs[i].timestamp2string = timestamp2string(logs[i].created_at);
            logs[i].key = logs[i].id ? '' + logs[i].id : guid();
            logs[i].token_name = logs[i].token_name ? logs[i].token_name.replace('的初始令牌', intl.get('components.LogsTable.initial_token')) : ''
            logs[i].content = logs[i].content ? logs[i].content : ''
            logs[i].content = logs[i].content.replace(/，充值转换率.*?秒/, '')
            logs[i].content = logs[i].content.replace('充值转换率 1.00，', '')
            logs[i].content = logs[i].content.replace('，充值转换率 1.00', '')
            logs[i].content = logs[i].content.replace(/\[(\d+)(美元)]/, (intl.get('components.LogsTable.dolor') + ' $1').replace(' ', ''))
            logs[i].content = logs[i].content.indexOf('通过在线充值') > -1 ? logs[i].content.split('，')[1] + '，' + logs[i].content.split('，')[0].replace('通过在线充值', intl.get('pages.TopUp.index.arrival-amount')) : logs[i].content
            logs[i].content = logs[i].content.replace('登录 IP', intl.get('components.LogsTable.login_ip'))
            logs[i].content = logs[i].content.replace('操作价格', intl.get('components.LogsTable.pperation_price'))
            logs[i].content = logs[i].content.replace('分组倍率', intl.get('components.LogsTable.group_rate'))
            logs[i].content = logs[i].content.replace('模式 ', intl.get('components.LogsTable.mode'))
            logs[i].content = logs[i].content.replace('操作 ', intl.get('components.LogsTable.mperation'))
            logs[i].content = logs[i].content.replace('MJ任务ID', intl.get('components.LogsTable.task_id'))
            logs[i].content = logs[i].content.replace('模型倍率', intl.get('components.LogsTable.model_rate'))
            logs[i].content = logs[i].content.replace('分组倍率', intl.get('components.LogsTable.group_rate'))
            logs[i].content = logs[i].content.replace('补全倍率', intl.get('components.LogsTable.output_rate'))
            logs[i].content = logs[i].content.replace('用时 5秒', intl.get('components.LogsTable.time_taken_seconds'))
            logs[i].content = logs[i].content.replace('支付金额', intl.get('components.LogsTable.payment_amount'))
            logs[i].content = logs[i].content.replace('额度', intl.get('components.LogsTable.amount_received_credit'))
            logs[i].content = logs[i].content.replace('通过兑换码充值', intl.get('components.LogsTable.recharge_via_code'))
            logs[i].content = logs[i].content.replace('构图失败，补偿', intl.get('components.LogsTable.imagine_failed_compensation'))
            logs[i].content = logs[i].content.replace('管理员为用户', intl.get('components.LogsTable.admin_for_user'))
            logs[i].content = logs[i].content.replace('充值', intl.get('components.LogsTable.recharged'))
            logs[i].content = logs[i].content.replace('额度（原因：管理员操作）', intl.get('components.LogsTable.credit_reason_admin_operation)'))
            logs[i].content = logs[i].content.replace('扣除', intl.get('components.LogsTable.deducted'))
            logs[i].content = logs[i].content.replace('邀请用户赠送', intl.get('components.LogsTable.invitation_user_gift'))
            logs[i].content = logs[i].content.replace('模型套餐实例id', intl.get('components.LogsTable.model_package_instance_id'))
            logs[i].content = logs[i].content.replace('套餐名称', intl.get('components.LogsTable.package_name'))
            logs[i].content = logs[i].content.replace('消耗', intl.get('components.LogsTable.consumption'))
            logs[i].content = logs[i].content.replace('用时', intl.get('components.LogsTable.time_taken'))
            logs[i].content = logs[i].content.replace('秒', ' ' + intl.get('components.LogsTable.seconds'))
            logs[i].content = logs[i].content.replace('删除令牌', ' ' + intl.get('components.LogsTable.delete_token'))
            logs[i].content = logs[i].content.replace('令牌ID', ' ' + intl.get('components.LogsTable.token_id'))
            logs[i].content = logs[i].content.replace('创建了新令牌，令牌名称', ' ' + intl.get('components.LogsTable.created_new_token_with_name'))
            logs[i].content = logs[i].content.replace('更新了令牌', ' ' + intl.get('components.LogsTable.update_token'))
            logs[i].content = logs[i].content.replace('（原因：管理员操作）', '')
            logs[i].content = logs[i].content.replace(/ (\d+(\.\d+)?)元/g, ' $ $1')
        }
        // data.key = '' + data.id
        setLogs(logs);
        // setLogCount(logs.length + pageSize);
        // console.log(logCount);
    };

    const loadLogs = async (startIdx, pageSize) => {
        setLoading(true);
        let url = '';
        let logCountUrl;
        let localStartTimestamp = Date.parse(start_timestamp) / 1000;
        let localEndTimestamp = Date.parse(end_timestamp) / 1000;
        if (isAdminUser) {
            url = `/api/log/?p=${startIdx}&pageSize=${pageSize}&type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`;
            logCountUrl= `api/log/count?type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`
        } else {
            url = `/api/log/self?p=${startIdx}&pageSize=${pageSize}&type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
            logCountUrl= `api/log/self/count?type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel=${channel}`
        }
        {
            let res = await API.get(logCountUrl);
            const {success, message, data} = res.data;
            if (success) {
                setLogCount(data.count);
            } else {
                showError(message);
            }
            setLoading(false);
        }
        {
            const res = await API.get(url);
            const {success, message, data} = res.data;
            if (success) {
                if (startIdx === 0) {
                    setLogsFormat(data);
                } else {
                    let newLogs = [...logs];
                    newLogs.splice(startIdx * pageSize, data.length, ...data);
                    setLogsFormat(newLogs);
                }
            } else {
                showError(message);
            }
        }
        setLoading(false);
    };

    const pageData = logs.slice((activePage - 1) * pageSize, activePage * pageSize);

    const handlePageChange = (page) => {
        setActivePage(page);
        loadLogs(page - 1, pageSize)
    };
    const handlePageSizeChange = size => {
        setPageSize(size)
        refresh(size)
    }

    const refresh = async (size = pageSize) => {
        // setLoading(true);
        setActivePage(1);
        await loadLogs(0, size);
    };

    const exportLog = () => {
        const wopts = {
            bookType: 'xlsx',
            bookSST: true,
            type: 'binary'
        };
        const workBook = {
            SheetNames: ['logs'],
            Sheets: {},
            Props: {}
        };
        const table = [];
        for (let i = 0; i < pageData.length; i++) {
            const item = pageData[i]
            table[i] = {
                [intl.get("components.LogsTable.time")]: item.timestamp2string,
                [intl.get("components.LogsTable.token")]: item.token_name,
                [intl.get("components.LogsTable.model")]: item.model_name,
                [intl.get("components.LogsTable.hint")]: item.prompt_tokens,
                [intl.get("components.LogsTable.complement")]: item.completion_tokens,
                [intl.get("components.LogsTable.quota")]: item.type === 0 || item.type === 2 ? renderQuota(item.quota, 6).trim() : ''
            }
        }
        workBook.Sheets['logs'] = XLSX.utils.json_to_sheet(table);
        FileSaver.saveAs(new Blob([changeData(XLSX.write(workBook, wopts))], {type: 'application/octet-stream'}), 'logs.xlsx');
    }
    const changeData = s => {
        if (typeof ArrayBuffer !== 'undefined') {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i) {
                view[i] = s.charCodeAt(i) & 0xFF;
            }
            return buf;
        } else {
            const buf = new Array(s.length);
            for (let i = 0; i !== s.length; ++i) {
                buf[i] = s.charCodeAt(i) & 0xFF;
            }
            return buf;
        }
    }
    const copyText = async (text) => {
        if (await copy(text)) {
            showSuccess(`${intl.get("components.LogsTable.copied")} ${text}`);
        } else {
            // setSearchKeyword(text);
            Modal.error({title: `${intl.get("components.LogsTable.cannot-copy-to-clipboard-please-copy-manually")}`, content: text});
        }
    };
    const loadStat = async() => {
        let logStatUrl
        if (isAdminUser) {
            logStatUrl = `/api/log/stat`
        } else {
            logStatUrl = `/api/log/self/stat`
        }
        const stat = await API.get(logStatUrl);
        let {success, message, data} = stat.data;
        if (success) {
            setStatInfo({
                mpm: data.mpm,
                quota: data.quota,
                rpm: data.rpm,
                tpm: data.tpm
            })
        } else {
            showError(message);
        }
    }

    const logTypeOptions = [
        {value: 0, label: intl.get("components.LogsTable.all")},
        {value: 1, label: intl.get("components.LogsTable.pay")},
        {value: 2, label: intl.get("components.LogsTable.consume")},
        {value: 3, label: intl.get("components.LogsTable.manage")},
        {value: 4, label: intl.get("components.LogsTable.system")},
    ]
    useEffect(() => {
        loadStat()
        refresh(pageSize).then();
    }, []);

    return (
        <Layout>
            <Header>
                <h3>
                    {intl.get("components.LogsTable.usage-details-total-consumption-amount")}
                </h3>
            </Header>
            <Form layout='horizontal' style={{marginTop: 10}}>
                <Form.Input
                    field='token_name'
                    label={intl.get("components.LogsTable.token-name")}
                    style={{width: 176}}
                    value={token_name}
                    placeholder={intl.get("components.LogsTable.optional-value")}
                    name='token_name'
                    onChange={(value) => handleInputChange(value, 'token_name')}
                />
                <Form.Input
                    field='model_name'
                    label={intl.get("components.LogsTable.model-name")}
                    style={{width: 176}}
                    value={model_name}
                    placeholder={intl.get("components.LogsTable.optional-value")}
                    name='model_name'
                    onChange={(value) => handleInputChange(value, 'model_name')}
                />
                <Form.DatePicker
                    field='start_timestamp'
                    label={intl.get("components.LogsTable.start-time")}
                    style={{width: 272}}
                    initValue={start_timestamp}
                    value={start_timestamp}
                    type='dateTime'
                    name='start_timestamp'
                    onChange={(value) => handleInputChange(value, 'start_timestamp')}
                />
                <Form.DatePicker
                    field='end_timestamp'
                    fluid
                    label={intl.get("components.LogsTable.end-time")}
                    style={{width: 272}}
                    initValue={end_timestamp}
                    value={end_timestamp}
                    type='dateTime'
                    name='end_timestamp'
                    onChange={(value) => handleInputChange(value, 'end_timestamp')}
                />
                {isAdminUser && (
                    <>
                        <Form.Input
                            field='channel'
                            label={intl.get("components.LogsTable.channel-ID")}
                            style={{width: 176}}
                            value={channel}
                            placeholder={intl.get("components.LogsTable.optional-value")}
                            name='channel'
                            onChange={(value) => handleInputChange(value, 'channel')}
                        />
                        <Form.Input
                            field='username'
                            label={intl.get("components.LogsTable.name-of-user")}
                            style={{width: 176}}
                            value={username}
                            placeholder={intl.get("components.LogsTable.optional-value")}
                            name='username'
                            onChange={(value) => handleInputChange(value, 'username')}
                        />
                    </>
                )}
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span className={'semi-form-field-label semi-form-field-label-left'}>{intl.get("components.LogsTable.log-type")}</span>
                    <Select
                        defaultValue={0}
                        style={{width: 120}}
                        label={intl.get("components.LogsTable.log-type")}
                        onChange={(value) => {
                            console.warn(111, typeof value, 222)
                            setLogType(parseInt(value));
                        }}
                        optionList={logTypeOptions}>
                    </Select>
                </div>
                <Button
                    style={{marginLeft: 20, marginTop: 25}}
                    label={intl.get("components.LogsTable.query")}
                    type='primary'
                    htmlType='submit'
                    className='btn-margin-right'
                    onClick={refresh}
                    loading={loading}>
                    {intl.get("components.LogsTable.query")}
                </Button>
                <Button
                    style={{marginLeft: 20, marginTop: 25}}
                    label={intl.get("components.LogsTable.export")}
                    type='primary'
                    htmlType='submit'
                    className='btn-margin-right'
                    onClick={exportLog}>
                    {intl.get("components.LogsTable.export")}
                </Button>
            </Form>
            <Header>
            <div style={{margin: '10px 0 10px 0'}}>
                {
                    intl.get("components.LogsTable.total-comsum") + ":" + (Number(statInfo.quota/500000).toFixed(2)) + " " +
                    intl.get("components.LogsTable.current-rpm") + ":" + statInfo.rpm + " " +
                    intl.get("components.LogsTable.current-tpm") + ":" + statInfo.tpm + " " +
                    intl.get("components.LogsTable.current-mpm") + ":$" + statInfo.mpm + " "
                }
            </div>
            </Header>
            <Table
                style={{marginTop: 5}}
                columns={columns}
                dataSource={pageData}
                pagination={{
                    currentPage: activePage,
                    pageSize: pageSize,
                    total: logCount,
                    showSizeChanger: true,
                    pageSizeOpts: [10, 20, 50, 100, 500, 1000],
                    onPageSizeChange: handlePageSizeChange,
                    onPageChange: handlePageChange,
                }}
            />
        </Layout>
    );
};

export default LogsTable;
