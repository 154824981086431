import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API, isMobile, showError, showSuccess, timestamp2string } from '../../helpers';
import { renderQuota, renderQuotaWithPrompt } from '../../helpers/render';
import {
	Layout,
	SideSheet,
	Button,
	Space,
	Spin,
	Banner,
	Input,
	DatePicker,
	AutoComplete,
	Typography,
	Checkbox,
	Icon,
	ButtonGroup,
	Select, InputNumber, Switch,
} from '@douyinfe/semi-ui';
import Title from '@douyinfe/semi-ui/lib/es/typography/title';
import { Divider } from 'semantic-ui-react';
import { intl } from '../../lang';

const EditToken = (props) => {
	const [isEdit, setIsEdit] = useState(false);
	const [loading, setLoading] = useState(!!isEdit);
	const originInputs = {
		name: '',
		remain_quota: isEdit ? 0 : 500000,
		remain_quota_temp: isEdit ? 0 : 1,
		expired_time: -1,
		unlimited_quota: false,
		models: '',
		ip_whitelist: '',
		rate_limit_duration: 0,
		rate_limit_num: 0,
		rate_limit_exceeded_message: ''
		// model_limits: [],
	};
	const billingTypeOptions = [
		{label: intl.get('pages.Token.EditToken.quantity_billing'), value: 1},
		{label: intl.get('pages.Token.EditToken.times_billing'), value: 2},
		{label: intl.get('pages.Token.EditToken.mixed'), value: 3},
		{label: intl.get('pages.Token.EditToken.quantity_first'), value: 4},
		{label: intl.get('pages.Token.EditToken.times_first'), value: 5}
	]
	const quickOptions = [1, 10,50,100,250,500]
	const [inputs, setInputs] = useState(originInputs);
	const { name, remain_quota, remain_quota_temp, expired_time, unlimited_quota,rate_limit_duration,rate_limit_num,rate_limit_exceeded_message,ip_whitelist,models,remark, billing_type } = inputs;
	const temp = expired_time === -1
	const [noneExpire, setNoneExpire] = useState(temp);
	// const [visible, setVisible] = useState(false);
	const [usableModels, setUsableModels] = useState({});
	const [model_limits_enabled, setModel_limits_enabled] = useState(true);
	const [enableLimiter, setEnableLimiter] = useState({});
	const navigate = useNavigate();
	const handleInputChange = (name, value) => {
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};
	const handleCancel = () => {
		props.handleClose();
	};
	const setExpiredTime = (month, day, hour, minute) => {
		let now = new Date();
		let timestamp = now.getTime() / 1000;
		let seconds = month * 30 * 24 * 60 * 60;
		seconds += day * 24 * 60 * 60;
		seconds += hour * 60 * 60;
		seconds += minute * 60;
		if (seconds !== 0) {
			timestamp += seconds;
			setInputs({ ...inputs, expired_time: timestamp2string(timestamp) });
		} else {
			setInputs({ ...inputs, expired_time: -1 });
		}
	};

	const setUnlimitedQuota = () => {
		setInputs({ ...inputs, unlimited_quota: !unlimited_quota });
	};

	const loadModels = async () => {
		let res = await API.get(`/api/user/available_model`);
		const { success, message, data } = res.data;
		if (success) {
			let localModelOptions = data.map((model) => ({
				label: model,
				value: model,
			}));
			setUsableModels(localModelOptions);
		} else {
			showError(message);
		}
	};

	const loadToken = async () => {
		setLoading(true);
		let res = await API.get(`/api/token/${props.editingToken.id}`);
		const { success, message, data } = res.data;
		if (success) {
			if (data.expired_time !== -1) {
				data.expired_time = timestamp2string(data.expired_time);
			}
			data.remain_quota_temp =  parseFloat(Number(data.remain_quota/500000).toFixed(2))
			// if (data.model_limits !== '') {
			// 	data.model_limits = data.model_limits.split(',');
			// } else {
			// 	data.model_limits = [];
			// }
			setInputs(data);
			setEnableLimiter(data.rate_limit_duration || data.rate_limit_num)
		} else {
			showError(message);
		}
		setLoading(false);
	};
	useEffect(() => {
		setIsEdit(props.editingToken.id !== undefined);
	}, [props.editingToken.id]);

	useEffect(() => {
		if (!isEdit) {
			setInputs(originInputs);
		} else {
			loadToken().then(() => {
				// console.log(inputs);
			});
		}
		loadModels();
	}, [isEdit]);

	// 新增 state 变量 tokenCount 来记录用户想要创建的令牌数量，默认为 1
	const [tokenCount, setTokenCount] = useState(1);

	// 新增处理 tokenCount 变化的函数
	const handleTokenCountChange = (value) => {
		// 确保用户输入的是正整数
		const count = parseInt(value, 10);
		if (!isNaN(count) && count > 0) {
			setTokenCount(count);
		}
	};

	// 生成一个随机的四位字母数字字符串
	const generateRandomSuffix = () => {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';
		for (let i = 0; i < 6; i++) {
			result += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return result;
	};

	const submit = async () => {
		setLoading(true);
		if (isEdit) {
			// 编辑令牌的逻辑保持不变
			let localInputs = { ...inputs };
			localInputs.remain_quota = parseInt(localInputs.remain_quota);
			if (localInputs.expired_time !== -1) {
				let time = Date.parse(localInputs.expired_time);
				if (isNaN(time)) {
					showError(`${intl.get('pages.Token.EditToken.expiration-time-format-error')}`);
					setLoading(false);
					return;
				}
				localInputs.expired_time = Math.ceil(time / 1000);
			}
			// localInputs.model_limits = localInputs.model_limits.join(',');
			let param = JSON.parse(JSON.stringify(localInputs))
			if (!model_limits_enabled) {
				delete param.ip_whitelist
				delete param.models
				delete param.rate_limit_duration
				delete param.rate_limit_exceeded_message
				delete param.rate_limit_num
			}
			if (!enableLimiter) {
				delete param.rate_limit_duration
				delete param.rate_limit_exceeded_message
				delete param.rate_limit_num
			}
			let res = await API.put(`/api/token/`, { ...param, id: parseInt(props.editingToken.id) });
			const { success, message } = res.data;
			if (success) {
				showSuccess(`${intl.get('pages.Token.EditToken.token-update-successfully')}`);
				props.refresh();
				props.handleClose();
			} else {
				showError(message);
			}
		} else {
			// 处理新增多个令牌的情况
			let successCount = 0; // 记录成功创建的令牌数量
			for (let i = 0; i < tokenCount; i++) {
				let localInputs = { ...inputs };
				if (i !== 0) {
					// 如果用户想要创建多个令牌，则给每个令牌一个序号后缀
					localInputs.name = `${inputs.name}-${generateRandomSuffix()}`;
				}
				localInputs.remain_quota = parseInt(localInputs.remain_quota);

				if (localInputs.expired_time !== -1) {
					let time = Date.parse(localInputs.expired_time);
					if (isNaN(time)) {
						showError(`${intl.get('pages.Token.EditToken.expiration-time-format-error')}`);
						setLoading(false);
						break;
					}
					localInputs.expired_time = Math.ceil(time / 1000);
				}
				// localInputs.model_limits = localInputs.model_limits.join(',');
				let res = await API.post(`/api/token/`, localInputs);
				const { success, message } = res.data;

				if (success) {
					successCount++;
				} else {
					showError(message);
					break; // 如果创建失败，终止循环
				}
			}

			if (successCount > 0) {
				showSuccess(`${successCount} ${intl.get('pages.Token.EditToken.create-token-successfully')}`);
				props.refresh();
				props.handleClose();
			}
		}
		setLoading(false);
		setInputs(originInputs); // 重置表单
		setTokenCount(1); // 重置数量为默认值
	};

	return (
		<>
			<SideSheet
				placement={isEdit ? 'right' : 'left'}
				title={
					<Title level={3}>
						{isEdit
							? `${intl.get('pages.Token.EditToken.update-token')}`
							: `${intl.get('pages.Token.EditToken.create-a-new-token')}`}
					</Title>
				}
				headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
				bodyStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
				visible={props.visiable}
				footer={
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Space>
							<Button theme='solid' size={'large'} onClick={submit}>
								{intl.get('pages.Token.EditToken.submit')}
							</Button>
							<Button theme='solid' size={'large'} type={'tertiary'} onClick={handleCancel}>
								{intl.get('pages.Token.EditToken.cancel')}
							</Button>
						</Space>
					</div>
				}
				closeIcon={null}
				onCancel={() => handleCancel()}
				width={isMobile() ? '100%' : 600}>
				<Spin spinning={loading}>
					<Input
						label={intl.get('pages.Token.EditToken.name')}
						name='name'
						placeholder={intl.get('pages.Token.EditToken.please-enter-name')}
						onChange={(value) => handleInputChange('name', value)}
						value={name}
						autoComplete='new-password'
						required={model_limits_enabled ? true : !isEdit}
					/>
					<Divider />
					<div style={{ marginTop: 10, display: 'flex' }}>
						<Typography.Text style={{width: 130}}>{intl.get('pages.Token.EditToken.unlimited-quota')}</Typography.Text>
						<Switch
							checked={unlimited_quota}
							onChange={value =>  handleInputChange('unlimited_quota', value)}
						></Switch>
					</div>
					{
						!unlimited_quota? <>
							{/*<Banner type={'warning'} description={intl.get('pages.Token.EditToken.token-quota-warning')}></Banner>*/}
							{/*<div style={{ marginTop: 20 }}>*/}
							{/*	<Typography.Text>{`${intl.get('pages.Token.EditToken.quota')}${renderQuotaWithPrompt(*/}
							{/*		remain_quota*/}
							{/*	)}`}</Typography.Text>*/}
							{/*</div>*/}
							<div style={{ marginTop: 10, display: 'flex' }}>
								<Typography.Text style={{width: 130}}>{intl.get('pages.Token.EditToken.remain_quota')}</Typography.Text>
								<Input name='remain_quota_temp' required={model_limits_enabled ? true : !isEdit} value={remain_quota_temp} onChange={value => handleInputChange('remain_quota_temp', value) | handleInputChange('remain_quota', value * 500000)} style={{ maxWidth: 'calc(100% - 150px)' }} suffix={<Typography.Text strong type='secondary' style={{ width: 20 }}>{/*intl.get('pages.Token.EditToken.dollar')*/}$</Typography.Text>} showClear></Input>
							</div>
							<div style={{ marginTop: 10, display: 'flex' }}>
								<Typography.Text style={{width: 130}}>{intl.get('pages.Token.EditToken.quick_options')}</Typography.Text>
								<ButtonGroup type={'primary'}>
									{
										quickOptions.map(item => (
											<Button onClick={() => handleInputChange('remain_quota_temp', item) | handleInputChange('remain_quota', item * 500000)} style={{width: 60}}><Typography.Text style={{marginRight: 2, color: '#4096ff'}}>$</Typography.Text><Typography.Text style={{color: '#4096ff'}}>{item}</Typography.Text></Button>
										))
									}
								</ButtonGroup>
								{/*<AutoComplete*/}
								{/*	placeholder={intl.get('pages.Token.EditToken.please-enter-quota')}*/}
								{/*	onChange={(value) => handleInputChange('remain_quota', value * 500000) && handleInputChange('remain_quota_a', value)}*/}
								{/*	autoComplete='new-password'*/}
								{/*	type='number'*/}
								{/*	//position={'top'}*/}
								{/*	data={[*/}
								{/*		{ value: 1, label: '$1' },*/}
								{/*		{ value: 10, label: '$10' },*/}
								{/*		{ value: 50, label: '$50' },*/}
								{/*		{ value: 100, label: '$100' },*/}
								{/*		{ value: 500, label: '$500' },*/}
								{/*		{ value: 1000, label: '$1000' },*/}
								{/*	]}*/}
								{/*	disabled={unlimited_quota}*/}
								{/*/>*/}
							</div>
						</>:''
					}
					<div style={{ marginTop: 10, display: 'flex' }}>
						<Typography.Text style={{width: 130}}>{intl.get('pages.Token.EditToken.no-expiration')}</Typography.Text>
						<Switch
							checked={noneExpire}
							onChange={value =>  setNoneExpire(value)}
						></Switch>
					</div>
					{
						!noneExpire ? <>
							<div style={{ marginTop: 10, display: 'flex' }}>
								<Typography.Text style={{width: 130}}>{intl.get('pages.Token.EditToken.expiration-time')}</Typography.Text>
								<DatePicker
									label={intl.get('pages.Token.EditToken.expiration-time')}
									name='expired_time'
									placeholder={intl.get('pages.Token.EditToken.please-select-an-expiration-time')}
									onChange={(value) => handleInputChange('expired_time', value)}
									value={expired_time}
									autoComplete='new-password'
									type='dateTime'
									required={model_limits_enabled ? true : !isEdit}/>
							</div>
						</>:''
					}
					<div style={{ marginTop: 10, display: 'flex' }}>
						<Typography.Text style={{marginTop: 10,width: 130}}>{intl.get('pages.Token.EditToken.billing_type')}</Typography.Text>
						<Select
							style={{ marginTop: 8, width: 'calc(100% - 150px)' }}
							name='billing_type'
							onChange={value => handleInputChange('billing_type', value)}
							value={billing_type}
							required={model_limits_enabled ? true : !isEdit}
							optionList={billingTypeOptions}
						/>
					</div>
					<div style={{ marginTop: 10, display: 'flex' }}>
						<Typography.Text style={{marginTop: 10,width: 130}}>{intl.get('pages.Token.EditToken.remark')}</Typography.Text>
						<Input
							style={{  maxWidth: 'calc(100% - 150px)' }}
							label={intl.get('pages.Token.EditToken.remark')}
							name='remark'
							placeholder={intl.get('pages.Token.EditToken.remark')}
							onChange={(value) => handleInputChange('remark', value)}
							value={remark}
							autoComplete='new-password'
							required={model_limits_enabled ? true : !isEdit}
						/>
					</div>
					<Divider />
					<div style={{ marginTop: 10, display: 'flex' }}>
						<Space>
							<Checkbox
								name='model_limits_enabled'
								checked={model_limits_enabled}
								onChange={e => setModel_limits_enabled(e.target.checked)}
							></Checkbox>
							<Typography.Text>{intl.get('pages.Token.EditToken.enable-model-restriction')}</Typography.Text>
						</Space>
					</div>
					<Divider />
					{
						model_limits_enabled ?
						<>
							<div style={{ marginTop: 10, display: 'flex' }}>
								<Typography.Text style={{marginTop: 10,width: 130}}>{intl.get('pages.Token.EditToken.usable_models')}</Typography.Text>
								<Select
									style={{ marginTop: 8, width: 'calc(100% - 150px)' }}
									name='models'
									multiple
									selection
									onChange={value => handleInputChange('models', value.join(","))}
									value={models.split(",").filter(item => item)}
									required={model_limits_enabled ? true : !isEdit}
									optionList={usableModels}
								/>
							</div>
							<div style={{ marginTop: 10, display: 'flex' }}>
								<Typography.Text style={{marginTop: 5,width: 130}}>{intl.get('pages.Token.EditToken.ip_whitelist')}</Typography.Text>
								<Input
									style={{ maxWidth: 'calc(100% - 150px)' }}
									name='ip_whitelist'
									onChange={(value) => handleInputChange('ip_whitelist', value)}
									value={ip_whitelist}
									autoComplete='new-password'
									required={model_limits_enabled ? true : !isEdit}
								/>
							</div>
							<Divider />
							<div style={{ marginTop: 10, display: 'flex' }}>
								<Typography.Text style={{width: 130}}>{intl.get('pages.Token.EditToken.limiter')}</Typography.Text>
								<Switch
									checked={enableLimiter}
									style={{}}
									onChange={value => setEnableLimiter(value)}
								></Switch>
							</div>
							{
								enableLimiter ?
								<>
									<div style={{ marginTop: 10, display: 'flex' }}>
										<Typography.Text style={{marginTop: 5,width: 130}}>{intl.get('pages.Token.EditToken.rate_limit_duration')}</Typography.Text>
										<InputNumber
											style={{ width: 130}}
											name='priority'
											onChange={value => {
												handleInputChange('rate_limit_duration', value)
											}}
											value={rate_limit_duration}
											required={model_limits_enabled ? true : !isEdit}
											min={0}
										/>
									</div>
									<div style={{ marginTop: 10, display: 'flex' }}>
										<Typography.Text style={{marginTop: 5,width: 130}}>{intl.get('pages.Token.EditToken.rate_limit_num')}</Typography.Text>
										<InputNumber
											style={{ width: 130 }}
											name='priority'
											onChange={value => {
												handleInputChange('rate_limit_num', value)
											}}
											value={rate_limit_num}
											required={model_limits_enabled ? true : !isEdit}
											min={0}
										/>
									</div>
									<div style={{ marginTop: 10, display: 'flex' }}>
										<Typography.Text style={{marginTop: 5,width: 130}}>{intl.get('pages.Token.EditToken.rate_limit_exceeded_message')}</Typography.Text>
										<Input
											style={{ maxWidth: 'calc(100% - 150px)' }}
											label={intl.get('pages.Token.EditToken.name')}
											name='name'
											placeholder={intl.get('pages.Token.EditToken.rate_limit_exceeded_message')}
											onChange={(value) => handleInputChange('rate_limit_exceeded_message', value)}
											value={rate_limit_exceeded_message}
											autoComplete='new-password'
											required={model_limits_enabled ? true : !isEdit}
										/>
									</div>
								</>: ''
							}
						</>	: ""
					}
				</Spin>
			</SideSheet>
		</>
	);
};

export default EditToken;
