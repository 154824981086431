import React, { useEffect, useState } from 'react';
import { API, copy, isAdmin, showError, showSuccess, timestamp2string } from '../helpers';

import { renderQuota } from '../helpers/render';
import {
	Table,
	Tag,
	Form,
	Button,
	Layout,
	Modal,
	ImagePreview,
	Typography,
	Progress,
} from '@douyinfe/semi-ui';
import { ITEMS_PER_PAGE } from '../constants';
import { intl } from '../lang';

const colors = [
	'amber',
	'blue',
	'cyan',
	'green',
	'grey',
	'indigo',
	'light-blue',
	'lime',
	'orange',
	'pink',
	'purple',
	'red',
	'teal',
	'violet',
	'yellow',
];

function renderType(type) {
	switch (type) {
		case 'IMAGINE':
			return (
				<Tag color='blue' size='large'>
					{intl.get('components.MjLogsTable.plot')}
				</Tag>
			);
		case 'UPSCALE':
			return (
				<Tag color='orange' size='large'>
					{intl.get('components.MjLogsTable.magnify')}
				</Tag>
			);
		case 'VARIATION':
			return (
				<Tag color='purple' size='large'>
					{intl.get('components.MjLogsTable.conversion')}
				</Tag>
			);
		case 'DESCRIBE':
			return (
				<Tag color='yellow' size='large'>
					{intl.get('components.MjLogsTable.describe')}
				</Tag>
			);
		case 'BLEAND':
			return (
				<Tag color='lime' size='large'>
					{intl.get('components.MjLogsTable.bleand')}
				</Tag>
			);
		default:
			return (
				<Tag color='white' size='large'>
					{intl.get('components.MjLogsTable.unknown')}
				</Tag>
			);
	}
}

function renderCode(code) {
	switch (code) {
		case 1:
			return (
				<Tag color='green' size='large'>
					{intl.get('components.MjLogsTable.submitted')}
				</Tag>
			);
		case 21:
			return (
				<Tag color='lime' size='large'>
					{intl.get('components.MjLogsTable.queuing')}
				</Tag>
			);
		case 22:
			return (
				<Tag color='orange' size='large'>
					{intl.get('components.MjLogsTable.resubmit')}
				</Tag>
			);
		default:
			return (
				<Tag color='white' size='large'>
					{intl.get('components.MjLogsTable.unknown')}
				</Tag>
			);
	}
}

function renderStatus(type) {
	// Ensure all cases are string literals by adding quotes.
	switch (type) {
		case 'SUCCESS':
			return (
				<Tag color='green' size='large'>
					{intl.get('components.MjLogsTable.succee')}
				</Tag>
			);
		case 'NOT_START':
			return (
				<Tag color='grey' size='large'>
					{intl.get('components.MjLogsTable.unstart')}
				</Tag>
			);
		case 'SUBMITTED':
			return (
				<Tag color='yellow' size='large'>
					{intl.get('components.MjLogsTable.in-queue')}
				</Tag>
			);
		case 'IN_PROGRESS':
			return (
				<Tag color='blue' size='large'>
					{intl.get('components.MjLogsTable.running')}
				</Tag>
			);
		case 'FAILURE':
			return (
				<Tag color='red' size='large'>
					{intl.get('components.MjLogsTable.fail')}
				</Tag>
			);
		default:
			return (
				<Tag color='white' size='large'>
					{intl.get('components.MjLogsTable.unknown')}
				</Tag>
			);
	}
}

const renderTimestamp = (timestampInSeconds) => {
	const date = new Date(timestampInSeconds * 1000); // 从秒转换为毫秒

	const year = date.getFullYear(); // 获取年份
	const month = ('0' + (date.getMonth() + 1)).slice(-2); // 获取月份，从0开始需要+1，并保证两位数
	const day = ('0' + date.getDate()).slice(-2); // 获取日期，并保证两位数
	const hours = ('0' + date.getHours()).slice(-2); // 获取小时，并保证两位数
	const minutes = ('0' + date.getMinutes()).slice(-2); // 获取分钟，并保证两位数
	const seconds = ('0' + date.getSeconds()).slice(-2); // 获取秒钟，并保证两位数

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 格式化输出
};

const LogsTable = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState('');
	const [adminUser] = useState(isAdmin())
	const columns = [
		{
			title: `${intl.get('components.MjLogsTable.submit-time')}`,
			dataIndex: 'submit_time',
			render: (text, record, index) => {
				return <div>{renderTimestamp(text / 1000)}</div>;
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.finish-time')}`,
			dataIndex: 'finish_time',
			render: (text) => {
				return <div>{text ? renderTimestamp(text / 1000) : '-'}</div>;
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.channel-id')}`,
			dataIndex: 'channel_id',
			hidden: !adminUser,
			render: (text, record, index) => {
				return (
					<div>
						<Tag
							color={colors[parseInt(text) % colors.length]}
							size='large'
							onClick={() => {
								copyText(text); // 假设copyText是用于文本复制的函数
							}}
						>
							{' '}
							{text}{' '}
						</Tag>
					</div>
				);
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.pattern')}`,
			dataIndex: 'mode',
			render: (text) => {
				return (
					<div>
						<Tag
							color={colors[parseInt(text) % colors.length]}
							size='large'
							onClick={() => {
								text && copyText(text); // 假设copyText是用于文本复制的函数
							}}
						>
							{text ?? '-'}
						</Tag>
					</div>
				);
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.action')}`,
			dataIndex: 'action',
			render: (text, record, index) => {
				return <div>{renderType(text)}</div>;
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.mj-id')}`,
			dataIndex: 'mj_id',
			render: (text, record, index) => {
				return <div>{text}</div>;
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.code')}`,
			dataIndex: 'code',
			className: isAdmin() ? 'tableShow' : 'tableHiddle',
			render: (text, record, index) => {
				return <div>{renderCode(text)}</div>;
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.status')}`,
			dataIndex: 'status',
			className: isAdmin() ? 'tableShow' : 'tableHiddle',
			render: (text, record, index) => {
				return <div>{renderStatus(text)}</div>;
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.progress')}`,
			dataIndex: 'progress',
			render: (text, record, index) => {
				return (
					<div>
						{
							// 转换例如100%为数字100，如果text未定义，返回0
							<Progress
								stroke={record.status === 'FAILURE' ? 'var(--semi-color-warning)' : null}
								percent={text ? parseInt(text.replace('%', '')) : 0}
								showInfo={true}
								aria-label='drawing progress'
							/>
						}
					</div>
				);
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.quota')}`,
			dataIndex: 'quota',
			render: (text, record, index) => {
				return text ? renderQuota(text) : '-';
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.image-url')}`,
			dataIndex: 'image_url',
			render: (text, record, index) => {
				if (!text) {
					return `${intl.get('components.MjLogsTable.null')}`;
				}
				return (
					<Button
						onClick={() => {
							setModalImageUrl(text.indexOf('api.maxapi.ai') > -1 ? text : text.replace('maxapi.ai', 'api.maxapi.ai').replace('www.maxapi.ai','api.maxapi.ai')); // 更新图片URL状态
							setIsModalOpenurl(true); // 打开模态框
						}}
					>
						{intl.get('components.MjLogsTable.see-all')}
					</Button>
				);
			},
		},
		{
			title: 'Prompt',
			dataIndex: 'prompt',
			render: (text, record, index) => {
				// 如果text未定义，返回替代文本，例如空字符串''或其他
				if (!text) {
					return `${intl.get('components.MjLogsTable.null')}`;
				}

				return (
					<Typography.Text
						ellipsis={{ showTooltip: true }}
						style={{ width: 100 }}
						onClick={() => {
							setModalContent(text);
							setIsModalOpen(true);
						}}
					>
						{text}
					</Typography.Text>
				);
			},
		},
		{
			title: 'PromptEn',
			dataIndex: 'prompt_en',
			render: (text, record, index) => {
				// 如果text未定义，返回替代文本，例如空字符串''或其他
				if (!text) {
					return `${intl.get('components.MjLogsTable.null')}`;
				}

				return (
					<Typography.Text
						ellipsis={{ showTooltip: true }}
						style={{ width: 100 }}
						onClick={() => {
							setModalContent(text);
							setIsModalOpen(true);
						}}
					>
						{text}
					</Typography.Text>
				);
			},
		},
		{
			title: `${intl.get('components.MjLogsTable.failure-reason')}`,
			dataIndex: 'fail_reason',
			render: (text, record, index) => {
				// 如果text未定义，返回替代文本，例如空字符串''或其他
				if (!text) {
					return `${intl.get('components.MjLogsTable.null')}`;
				}

				return (
					<Typography.Text
						ellipsis={{ showTooltip: true }}
						style={{ width: 100 }}
						onClick={() => {
							setModalContent(text);
							setIsModalOpen(true);
						}}
					>
						{text}
					</Typography.Text>
				);
			},
		},
	];

	const [logs, setLogs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activePage, setActivePage] = useState(1);
	const [logCount, setLogCount] = useState(ITEMS_PER_PAGE);
	const [logType, setLogType] = useState(0);
	const isAdminUser = isAdmin();
	const [isModalOpenurl, setIsModalOpenurl] = useState(false);

	// 定义模态框图片URL的状态和更新函数
	const [modalImageUrl, setModalImageUrl] = useState('');
	let now = new Date();
	// 初始化start_timestamp为前一天
	const [inputs, setInputs] = useState({
		channel_id: '',
		mj_id: '',
		start_timestamp: timestamp2string(now.getTime() / 1000 - 2592000),
		end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
	});
	const { channel_id, mj_id, start_timestamp, end_timestamp } = inputs;

	const [stat, setStat] = useState({
		quota: 0,
		token: 0,
	});

	const handleInputChange = (value, name) => {
		setInputs((inputs) => ({ ...inputs, [name]: value }));
	};

	const setLogsFormat = (logs) => {
		for (let i = 0; i < logs.length; i++) {
			logs[i].timestamp2string = timestamp2string(logs[i].created_at);
			logs[i].key = '' + logs[i].id;
			logs[i].fail_reason = logs[i].fail_reason ? logs[i].fail_reason : ''
			logs[i].fail_reason = logs[i].fail_reason.replace('可能包含违规信息', intl.get('components.MjLogsTable.contains_sensitive_words'))
		}
		// data.key = '' + data.id
		setLogs(logs);
		setLogCount(logs.length + ITEMS_PER_PAGE);
		// console.log(logCount);
	};

	const loadLogs = async (startIdx) => {
		setLoading(true);

		let url = '';
		let localStartTimestamp = Date.parse(start_timestamp);
		let localEndTimestamp = Date.parse(end_timestamp);
		if (isAdminUser) {
			url = `/api/mj/?p=${startIdx}&mj_id=${mj_id}&submit_time_start=${localStartTimestamp}&submit_time_end=${localEndTimestamp}`;
		} else {
			url = `/api/mj/self?p=${startIdx}&mj_id=${mj_id}&submit_time_start=${localStartTimestamp}&submit_time_end=${localEndTimestamp}`;
		}
		const res = await API.get(url);
		const { success, message, data } = res.data;
		if (success) {
			if (startIdx === 0) {
				setLogsFormat(data);
			} else {
				let newLogs = [...logs];
				newLogs.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
				setLogsFormat(newLogs);
			}
		} else {
			showError(message);
		}
		setLoading(false);
	};

	const pageData = logs.slice((activePage - 1) * ITEMS_PER_PAGE, activePage * ITEMS_PER_PAGE);

	const handlePageChange = (page) => {
		setActivePage(page);
		if (page === Math.ceil(logs.length / ITEMS_PER_PAGE) + 1) {
			// In this case we have to load more data and then append them.
			loadLogs(page - 1).then((r) => {});
		}
	};

	const refresh = async () => {
		// setLoading(true);
		setActivePage(1);
		await loadLogs(0);
	};

	const copyText = async (text) => {
		if (await copy(text)) {
			showSuccess(`${intl.get('components.MjLogsTable.copied')}` + text);
		} else {
			// setSearchKeyword(text);
			Modal.error({ title: `${intl.get('components.MjLogsTable.cannot-copy-to-clipboard-please-copy-manually')}`, content: text });
		}
	};

	useEffect(() => {
		refresh().then();
	}, [logType]);

	return (
		<>
			<Layout>
				<Form layout='horizontal' style={{ marginTop: 10 }}>
					<>
						<Form.Input
							field='channel_id'
							label={intl.get('components.MjLogsTable.channel-ID')}
							style={{ width: 176 }}
							value={channel_id}
							placeholder={intl.get('components.MjLogsTable.optional-value')}
							name='channel_id'
							onChange={(value) => handleInputChange(value, 'channel_id')}
						/>
						<Form.Input
							field='mj_id'
							label={intl.get('components.MjLogsTable.mj-id')}
							style={{ width: 176 }}
							value={mj_id}
							placeholder={intl.get('components.MjLogsTable.optional-value')}
							name='mj_id'
							onChange={(value) => handleInputChange(value, 'mj_id')}
						/>
						<Form.DatePicker
							field='start_timestamp'
							label={intl.get('components.MjLogsTable.start-time')}
							style={{ width: 272 }}
							initValue={start_timestamp}
							value={start_timestamp}
							type='dateTime'
							name='start_timestamp'
							onChange={(value) => handleInputChange(value, 'start_timestamp')}
						/>
						<Form.DatePicker
							field='end_timestamp'
							fluid
							label={intl.get('components.MjLogsTable.end-time')}
							style={{ width: 272 }}
							initValue={end_timestamp}
							value={end_timestamp}
							type='dateTime'
							name='end_timestamp'
							onChange={(value) => handleInputChange(value, 'end_timestamp')}
						/>

						<Form.Section>
							<Button label={intl.get('components.MjLogsTable.query')} type='primary' htmlType='submit' className='btn-margin-right' onClick={refresh}>
								{intl.get('components.MjLogsTable.query')}
							</Button>
						</Form.Section>
					</>
				</Form>
				<Table
					columns={columns}
					dataSource={pageData}
					pagination={{
						currentPage: activePage,
						pageSize: ITEMS_PER_PAGE,
						total: logCount,
						// showSizeChanger: true,
						// pageSizeOpts: [10, 20, 50, 100, 500],
						onPageChange: handlePageChange,
					}}
					loading={loading}
				/>
				<Modal
					visible={isModalOpen}
					onOk={() => setIsModalOpen(false)}
					onCancel={() => setIsModalOpen(false)}
					closable={null}
					bodyStyle={{ height: '400px', overflow: 'auto' }} // 设置模态框内容区域样式
					width={800} // 设置模态框宽度
				>
					<p style={{ whiteSpace: 'pre-line' }}>{modalContent}</p>
				</Modal>
				<ImagePreview src={modalImageUrl} visible={isModalOpenurl} onVisibleChange={(visible) => setIsModalOpenurl(visible)} />
			</Layout>
		</>
	);
};

export default LogsTable;
